<template>
  <div class="ctrlurl">
    <v-text-field
      v-bind="$input"
      v-model="schema.value"
      :label="schema.label"
      :append-icon="icono"
      :readonly="!edicion"
      @click:append="show_url"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    schema: { type: [Object, Array], required: true },
    edicion: { type: Boolean, default: false },
    icono: { type: String, default: "mdi-web" }
  },

  data() {
    return {};
  },

  methods: {
    //
    show_url() {
      if (this.schema.value === "") return;
      window.open(this.schema.value, "_blank");
    }
  }
};
</script>
